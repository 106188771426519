import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../views/welcome.vue'),
    meta: {
      title: '欢迎',
      keepAlive: true
    }
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/loginPage.vue'),
  //   meta: {
  //     title: '登录',
  //     keepAlive: false
  //   }
  // },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/mine/index.vue'),
    meta: {
      title: '我的',
      keepAlive: true
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      title: '雨诺盘点',
      keepAlive: true
    }
  },
  {
    path: '/check',
    name: 'Check',
    component: () => import('../views/StaticCheck/check.vue'),
    meta: {
      title: '盘点',
      keepAlive: true
    }
  },
  {
    path: '/addcheck',
    name: 'addCheck',
    component: () => import('../views/StaticCheck/addCheck.vue'),
    meta: {
      title: '新增盘点方案',
      keepAlive: false
    }
  },
  {
    path: '/plandetailcheck',
    name: 'plandetailcheck',
    component: () => import('../views/StaticCheck/planDetail.vue'),
    meta: {
      title: '盘点方案详情',
      keepAlive: false
    }
  },
  {
    path: '/checkdetail',
    name: 'checkDetail',
    component: () => import('../views/StaticCheck/checkDetail.vue'),
    meta: {
      title: '商品批号盘点',
      keepAlive: true
    }
  },
  {
    path:'/numbercheck',
    name:'numberCheck',
    component: () => import('../views/StaticCheck/numberCheck.vue'),
    meta: {
      title: '商品盘点详情',
      keepAlive: false
    }
  },
  {
    path:'/numbercheckmany',
    name: 'numberCheckMany',
    component: () => import('../views/StaticCheck/numberCheckMany.vue'),
    meta: {
      title: '批号盘点详情',
      keepAlive: false
    }
  },
  {
    path:'/numberadd',
    name: 'numberadd',
    component: () => import('../views/StaticCheck/numberAdd.vue'),
    meta: {
      title: '新增批号',
      keepAlive: true
    }
  },
  {
    path:'/numberaddchoose',
    name: 'numberaddchoose',
    component: () => import('../views/StaticCheck/numberAddchoose.vue'),
    meta: {
      title: '新增批号',
      keepAlive: false
    }
  },
  {
    path:'/checkDraw',
    name: 'checkDraw',
    component: () => import('../views/cherktypeone/checkDraw.vue'),
    meta: {
      title: '抽盘',
      keepAlive: false
    }
  },
  {
    path:'/checkdrawinfo',
    name:'checkdrawinfo',
    component:()=>import('../views/cherktypeone/checkDrawInfo.vue'),
    meta: {
      title: '抽盘信息',
      keepAlive: false
    }
  },
  {
    path:'/checkdel',
    name:'checkdel',
    component:()=>import('../views/cherktypeone/checkdel.vue'),
    meta: {
      title: '方案信息',
      keepAlive: false
    }
  },
  {
    path:'/checkSearch',
    name:'checkSearch',
    component:()=>import('../views/cherktypeone/checkSearch.vue'),
    meta: {
      title: '商品搜索',
      keepAlive: false
    }
  },
  {
    path:'/checkdrawdetail',
    name:'checkdrawdetail',
    component:()=>import('../views/cherktypeone/checkDetail.vue'),
    meta: {
      title: '盘点详情',
      keepAlive: false
    }
  },
  {
    path:'/checkdocking',
    name: 'checkdocking',
    component: ()=>import('../views/checkDocking/checkDocking.vue'),
    meta: {
      title: '交接班',
      keepAlive: false
    }
  },
  {
    path:'/checkdockingdetail',
    name: 'checkdockingdetail',
    component: ()=>import('../views/checkDocking/checkDockingDetail.vue'),
    meta: {
      title: '交接盘商品展示',
      keepAlive: true
    }
  },
  {
    path:'/dockingNum',
    name: 'dockingNum',
    component: ()=>import('../views/checkDocking/dockingNum.vue'),
    meta: {
      title: '交接盘明细批号盘点',
      keepAlive: false
    }
  },
  {
    path:'/dockingNumGoods',
    name: 'dockingNumGoods',
    component: ()=>import('../views/checkDocking/dockingNumGoods.vue'),
    meta: {
      title: '交接盘新增批号商品选择',
      keepAlive: true
    }
  },
  {
    path:'/dockingNumAdd',
    name: 'dockingNumAdd',
    component: ()=>import('../views/checkDocking/dockingNumAdd.vue'),
    meta: {
      title: '交接盘新增批号',
      keepAlive: false
    }
  },
  {
    path: '/checkInspect',
    name: 'checkInspect',
    component: ()=>import('../views/checkInspect/checkInspect.vue'),
    meta: {
      title: '检查盘',
      keepAlive: true
    }
  },
  {
    path: '/checkInsdetail',
    name: 'checkInsdetail',
    component: ()=>import('../views/checkInspect/checkInsdetail.vue'),
    meta: {
      title: '检查盘点详情',
      keepAlive: true
    }
  },
  {
    path: '/checkInsgoods',
    name: 'checkInsgoods',
    component: ()=>import('../views/checkInspect/checkInsgoods.vue'),
    meta: {
      title: '检查盘商品盘点',
      keepAlive: false
    }
  },
  {
    path: '/checkInsnumber',
    name: 'checkInsnumber',
    component: ()=>import('../views/checkInspect/checkInsnumber.vue'),
    meta: {
      title: '检查盘批号盘点',
      keepAlive: false
    }
  },
  {
    path: '/checkInsinfo',
    name: 'checkInsinfo',
    component: ()=>import('../views/checkInspect/checkInsinfo.vue'),
    meta: {
      title: '检查盘方案详情',
      keepAlive: false
    }
  },
  {
    path:'/instructions',
    name: 'instructions',
    component:()=>import('../views/mine/instructions.vue'),
    meta: {
      title: '操作说明',
      keepAlive: true
    }
  },
  {
    path:'/equipment',
    name: 'equipment',
    component:()=>import('../views/mine/equipment.vue'),
    meta: {
      title: '设置编号',
      keepAlive: false
    }
  },
  // {
  //   path: '/interfacetest',
  //   name: 'interfacetest',
  //   component: ()=>import('../views/Interfacetest.vue'),
  //   meta: {
  //     title: "接口测试"
  //   }
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  // base: process.env.BASE_URL,
  routes
})

export default router
