<template>
<div class="home">
  <van-pull-refresh class="refreshbox" v-model="isLoading" @refresh="onRefresh">
    <div class="personnel">
      <!-- 总部、门店 标签 -->
      <div class="org" @click="changeOrg()">
        <!-- <van-icon name="exchange" />  -->
        <span>{{org == 'True'?'门店':'总部'}}</span>
      </div>
      <div class="personbox">
        <!-- 头像 -->
        <div class="imgbox">
          <img :src="imgurl?imgurl:require('@/assets/home/default.png')" alt="">
        </div>
        <div class="personinfo">
          <div class="selectbox">
            <div class="orgtitle">操作人员</div>
            <div class="orgname">{{userquery.OPERATORNAME || '--'}}（{{userquery.OPERATORCODE || '--'}}）</div>
          </div>
          <div class="selectbox" @click="orgselect()">
            <div class="orgtitle">组织机构</div> 
            <div class="orgname">{{orgName}}</div>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </div>
      <div class="bgbox">
        <img src="@/assets/home/topbg.png" alt="">
      </div>
    </div>
    <!-- 工作台 -->
    <div class="myworkbench">
      <div class="bench-name">我的工作台</div>
      <div class="gridbox">
        <div class="bg-item" v-for="(value,index) in workList" :key="index" @click="onclick(value)">
          <div class="iconbox">
            <img v-if="['MD_ALLPD','ZB_ALLPD'].includes(value.CODE)" src="@/assets/home/quanpan.png" alt=""> <!-- 全盘 -->
            <img v-if="['ZB_EXTRACTPD'].includes(value.CODE)" src="@/assets/home/choupan.png" alt=""> <!-- 抽盘 -->
            <img v-if="['MD_SELECTPD','ZB_SELECTPD'].includes(value.CODE)" src="@/assets/home/xuanpan.png" alt=""> <!-- 选盘 -->
            <img v-if="['ZB_STOCKPD','MD_STOCKPD'].includes(value.CODE)" src="@/assets/home/cangpan.png" alt=""> <!-- 仓盘 -->
            <img v-if="['ZB_TRENDSPD','MD_TRENDSPD'].includes(value.CODE)" src="@/assets/home/dxpan.png" alt=""> <!-- 动销盘 -->
            <img v-if="['MD_JJBPD'].includes(value.CODE)" src="@/assets/home/handover.png" alt=""> <!-- 交接盘 -->
            <img v-if="['MD_BTYPD'].includes(value.CODE)" src="@/assets/home/nostop.png" alt=""> <!-- 不停业 -->
            <img v-if="['MD_JDPD'].includes(value.CODE)" src="@/assets/home/jiediao.png" alt=""> <!-- 借调盘 -->
            <img v-if="['MD_CHECKPD'].includes(value.CODE)" src="@/assets/home/check.png" alt=""> <!-- 检查盘 -->
          </div>
          <div class="bg-item-name">{{value.盘点形式}}</div>
        </div>
      </div>
      <!-- <van-grid :column-num="3" :clickable="true">
        <template v-for="(value,index) in workList">
          <van-grid-item :key="index" icon="photo-o" :text="value.盘点形式" :badge="value.方案条目" @click="onclick(value)" />
        </template>
      </van-grid> -->
    </div>
  </van-pull-refresh>
  <!-- 组织机构选择框 -->
  <van-action-sheet v-model="show" title="选择组织机构">
    <div class="content">
      <van-search v-model="orgvalue" @search="onOrgSearch" show-action placeholder="请输入搜索关键词"> 
        <template #action>
            <div @click="onOrgSearch">搜索</div>
        </template>
      </van-search>
      <van-radio-group v-model="radio" class="radiobox">
        <van-cell-group>
          <van-cell v-for="(item,index) in orglist" :key="index" clickable @click="onradio(index,item)">
            <template #title>
              <span class="custom-title">{{item.NAME}}</span>
            </template>
            <template #right-icon>
              <van-radio :name="index" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <!-- <van-button class="btn" type="info" @click="onworkBrank()">确定</van-button> -->
    </div>
  </van-action-sheet>
  <!-- <div class="logobox">
    <div class="logo" @click="test">
      <img src="@/assets/logo.png" alt="">
    </div>
  </div> -->
  <!-- <div class="guide">
    <div class="guideImg"><img src="@/assets/home/Group.png" alt=""></div>
    <div class="guidetext">操作指南</div>
  </div> -->
  <div class="homebgbox">
    <img src="@/assets/home/bg.png" alt="">
  </div>
</div>
</template>
<script>
import { getCode,dingdingTitle } from '@/util/env.js'
import {HomeConfigQuery,SubQuery} from "@/util/api.js"
import homecofig from './testdata/homeconfig.json'
import orglist from './testdata/orglist.json'
export default {
  name: 'Home',
  components: {
    
  },
  props:{
    
  },
  data() {
    return {
      isLoading: false,
      development: localStorage.getItem('development'), // 开发模式
      issub:'', //总部还是门店
      branchguid:'', // 机构编号
      orgName:'', // 当前组织机构名称
      orgNameflag:'', // 当前选中的组织机构，暂时存放
      imgurl:'', // 头像
      userquery:{}, // 当前人员信息
      workList:[], // 工作台列表
      orgtype:'ZB', // 当前组织机构
      org:'',
      show: false, // 控制组织机构切换弹出框
      orgvalue:'', // 组织机构搜索框
      radio: 0,
      orglist:[], // 组织机构列表
      filterstr:'', //组织机构筛选条件
    }
  },
  methods:{
    onRefresh() {
      setTimeout(() => {
          this.$toast('刷新成功');
          this.isLoading = false;
          this.init();
      }, 500);
    },
    init(){ // 初始化 请求工作台的列表接口
      this.getWorkBrank()
    },
    getWorkBrank(){ // 获取工作台列表
      var data = {
        "ISSUB":localStorage.getItem("orgissub") || "True",
        "BRANCHGUID":localStorage.getItem("orgGuid")
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      // console.log(data)
      HomeConfigQuery(data).then(res=>{
        this.$toast.clear();
        console.log(res)
        if(res.Code === 0){
          if(res.Message){
            this.$toast(res.Message)
          }
          this.workList = res.Data
        }else if(res.Code == 2 && res.Message.includes('DINGERRORCODE:40078')){
          getCode(code => {
            localStorage.setItem("dingcode",code);
            this.getWorkBrank();
          })
        }else{
          this.$dialog.alert({
            // title: "数据接口请求异常",
            message: res.Message || res.Msg || '接口参数错误',
          }).then(()=> {})
        }
      }).catch(err=>{
        this.$toast.clear();
        this.$dialog.alert({
          title: "服务器响应异常",
          message: JSON.stringify(err),
        }).then(()=> {})
      })
    },
    orgselect(){ // 打开组织机构搜索弹出框
      this.show = !this.show
    },
    onOrgSearch(){ // 搜索组织机构
      console.log(this.orgvalue)
      this.filterstr = this.orgvalue || '%'
      this.onclickOrg()
    },
    onclickOrg(){
      var data = {
        "FILTERSTR": this.filterstr
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      SubQuery(data).then(res=>{ // 查询组织机构
          this.$toast.clear();
          console.log(res)
          if(res.Code === 0){ // 成功
          	if(res.Data){
          		localStorage.setItem("orglist",JSON.stringify(res.Data))
              this.orglist = res.Data
              this.radio = ""
              this.issub = res.Data[0].ISSUB
              this.branchguid = res.Data[0].GUID
              this.orgNameflag = res.Data[0].NAME
          	}else{
          		this.$toast('没有找到该组织机构！');
          	}  
          }else if(res.Code == 2 && res.Message.includes('DINGERRORCODE:40078')){
            getCode(code => {
              localStorage.setItem("dingcode",code);
              this.onclickOrg();
            })
          }else{
              this.$dialog.alert({
                  // title: "数据接口请求异常",
                  message: res.Message || res.Msg || '接口参数错误',
              }).then(()=> {})
          }
      }).catch(err=>{
        this.$toast.clear();
          this.$dialog.alert({
              title: "服务器响应异常",
              message: JSON.stringify(err),
          }).then(()=> {})
      })
    },
    onradio(index,item){ // 组织结构单选触发方法
      this.radio = index
      this.issub = item.ISSUB
      this.branchguid = item.GUID
      this.orgNameflag = item.NAME
      this.onworkBrank()
    },
    onworkBrank(){ // 组织机构确定选择按钮
      this.show = !this.show
      localStorage.setItem("orgName",this.orgNameflag)
      localStorage.setItem("orgGuid",this.branchguid)
      localStorage.setItem("orgissub",this.issub)
      this.org = localStorage.getItem('orgissub')
      this.orgName = this.orgNameflag
      this.getWorkBrank()
    },
    test(){ // 接口测试
      this.$router.push({ path:'/interfacetest' })
    },
    clickmine(){ // 去往我的页面，查看个人信息
      this.$router.push({path:'/mine'})
    },
//  changeOrg(){ // 切换门店 总部与门店的切换
//    this.orgtype = this.orgtype === 'ZB' ? 'MD':'ZB'
//    this.org = this.orgtype === 'ZB' ? '门店':'总部'
//  },
    onclick(value){
      console.log(value)
      localStorage.setItem("onClerk",JSON.stringify(value))
      if(value.查询权限 == '0'){
        this.$dialog.alert({
          title: "您没有操作此功能的权限",
        })
        return 
      }
      if(value.类型 === '1'){  // 静态盘点通用界面
        this.$router.push({ path:'/check',query:{pagetitle:value.盘点形式, mode:value.mode} })
      }else if(value.类型 === '2'){ // 抽盘界面
        this.$router.push({ path:'/checkDraw',query:{type:value.flag,pagetitle:value.盘点形式} })
      }else if(value.类型 === '3'){ //交班盘点界面
        this.$router.push({ path:'/checkdocking',query:{ pagetitle:value.盘点形式 } })
      }else if(value.类型 === '4'){
        this.$router.push( { path:'/checkInspect', query:{ pagetitle:value.盘点形式 } } )
      }
    },
  },
  created(){
    this.userquery = JSON.parse(localStorage.getItem('userquery'))
    // console.log(localStorage)
    this.imgurl = this.userquery.ICONURL
    this.orgName = localStorage.getItem('orgName')
    this.org = localStorage.getItem('orgissub')
    if(this.development == '0'){
      this.orglist = orglist
      this.workList = homecofig
    }else{
      this.init()
    }
  },
  activated(){
    this.orgName = localStorage.getItem('orgName')
    this.org = localStorage.getItem('orgissub')
    var env = localStorage.getItem('env')
    if(env == 'dd-mobile'){
      dingdingTitle("雨诺盘点")
    }else{
      window.document.title = "雨诺盘点"
    }
  }
}
</script>
<style lang="scss" scoped>
.home{
  width:100%;
  height:100vh;
  background: #EFF2FA;
  position: relative;
  .refreshbox{
    position: relative;
    height:100%;
    z-index: 1;
  }
  .homebgbox{
    position: absolute;
    top:0; left:0;
    width:100%;
    z-index: 0;
  }
}
// 人员信息
.personnel{
  padding:0.76rem 0.23rem 0.51rem;
  position: relative;
  margin:0.3rem 0.2rem;
  border-radius: 0.16rem;
  background: linear-gradient(133deg, #84B1FF 0%, #4187FF 100%);
  box-shadow: 0px 10px 16px 0px rgba(75, 127, 255, 0.1);  
  z-index: 2;
  // 背景图
  .bgbox{
    position: absolute;
    top:0;left: 0;
    width:100%;
  }
  .personbox{
    display: flex;
    align-items: center;
  }
  .imgbox{
    width:1.16rem;
    height:1.16rem;
    border-radius: 50% 50%;
    overflow: hidden;
    z-index: 3;
  }
  .personinfo{
    margin-left:0.21rem;
    font-size: 0.3rem;
    color: #FFFFFF;
    z-index: 3;
    .selectbox{
      margin-top:0.2rem;
      display:flex;
      align-items: center;
      .orgtitle{
        width:1.6rem;
      }
      .orgname{
        max-width:3.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .org{
    position: absolute;
    top:0.32rem;right:0;
    width:1.03rem;
    height:0.44rem;
    line-height: 0.44rem;
    color:#FFFFFF;
    font-size: 0.28rem;
    text-align: center;
    background: linear-gradient(43deg, #F8C272 0%, #FFA84B 100%);
    border-radius: 24px 0px 0px 24px;
    z-index: 3;
  }
}
// 工作台
.myworkbench{
  position: relative;
  margin:0.3rem 0.2rem 0;
  border-radius: 8px;
  background: #FFFFFF;
  z-index: 1;
  .bench-name{
    padding:0.25rem 0 0.24rem 0.25rem;
    color: #666666;
    font-size: 0.3rem;
    font-weight: bold;
    border-bottom:1px solid #E8ECF5;
  }
  .gridbox{
    margin-top:4px;
    display: flex;
    flex-wrap: wrap;
    .bg-item{
      margin:0.25rem 0;
      width:25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .iconbox{
        width:0.888rem;
        height:0.888rem;
      }
      .bg-item-name{
        margin-top:0.19rem;
        color:#666666;
        font-size: 0.32rem;
      }
    }
  }
}
// 操作指南
.guide{
  position: absolute;
  right:0;
  bottom: 1.58rem;
  width: 1.9rem;
  height: 0.66rem;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #4187FF 0%, #5A97FF 100%);
  box-shadow: 0px 10px 10px 0px #DFE6FF;
  border-radius: 100px 0px 0px 100px;
  .guideImg{
    margin-left: 0.25rem;
    width: 0.24rem;
    height: 0.24rem;
  }
  .guidetext{
    margin-left: 0.07rem;
    font-size: 0.26rem;
    color: #FFFFFF;
  }
}
// 底部logo
.logobox{
position: absolute;
bottom: 0.5rem;left:0;
width:100%;
height:1.5rem;
margin:auto;
.logo{
  width:6rem;
  position: absolute;
  left: 50%;
  top: 50%;   
  transform: translate(-50%, -50%); 
}
}

// 弹出框
.content {
  height:70vh;
  background: #EFF2FA;
}
.radiobox{
  margin-top:0.1rem;
  height:calc(70vh - 54px - 0.2rem);
  overflow-y:auto;
}
.custom-title{
  margin-right: 4px;
  vertical-align: middle;
  text-align: left;
}
// .btn{
//   margin-top:0.1rem;
//   width:80%;
//   height:1rem;
// }
</style>
