// 环境判断
import * as dd from 'dingtalk-jsapi'; 

export function getCode(callback){
  // let corpId = corpId;
  // 将corpid存入localStorage
  localStorage.setItem('corpid',ddcorpId)
  if (dd.env.platform !== "notInDingTalk") {
    // dd.ready(() => {
      //使用SDK 获取免登授权码
      dd.runtime.permission.requestAuthCode({
        corpId: ddcorpId,
        onSuccess: (info) => {
          // 根据钉钉提供的api 获得code后,再次调用这个callback方法
          // 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
          // callback 函数会等他执行完毕后在自己调用自己
          callback(info.code)
        },
        onFail: (err) => {
          // alert('fail');
          alert(JSON.stringify(err));
        },
      });
    // });
  }else{
    alert('这不是钉钉环境')
  }
}
// 企微鉴权
export function setqwAPI(data) {
  // 将corpid存入localStorage
  localStorage.setItem('corpid',qwcorpId)
  return new Promise((resolve, reject) => {
      wx.config({
          beta: true,
          debug: false,
          // appId: stateItem[0],
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareWeibo',
              'onMenuShareQZone',
              // 'setNavigationBarColor',
              'setBounceBackground',
              'openUserProfile',
              'selectEnterpriseContact',
              'sendChatMessage',
              'chooseImage',
              'agentConfig'
          ]
      });
      wx.ready(function () {
          // alert("config调用成功")
      });
      wx.error(function (err) {
          // alert("错误");
          alert('alert4 == '+JSON.stringify(err));
      })
  })
}

// 调用扫码功能
export function getScan(callback){
  // 获取当前环境值 
  var env = localStorage.getItem('env')
  if(env == 'dd-mobile'){
    dd.biz.util.scan({
      type: 'all' , // type 为 all、qrCode、barCode，默认是all。
        onSuccess: function(data) {
        //onSuccess将在扫码成功之后回调
          /* data结构
            { 'text': String}
          */
         callback(data.text)
      },
      onFail : function(err) {
      }
    })
  }else if(env == 'com-wx-mobile'){
    wx.scanQRCode({
        desc: 'scanQRCode desc',
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function(res) {
            // 回调
            callback(res.resultStr)
        },
        error: function(res) {
            if (res.errMsg.indexOf('function_not_exist') > 0) {
                alert('版本过低请升级')
            }
        }
    });
  }
}

// 钉钉修改页面title
export function dingdingTitle(title){
  dd.biz.navigation.setTitle({
    title : title,//控制标题文本，空字符串表示显示默认文本
    onSuccess : function(result) {
        /*结构
        {
        }*/
    },
    onFail : function(err) {}
  });
}

// 退出钉钉微应用
export function signOutdd(){
  dd.ready(function() {
      dd.biz.navigation.close({
        onSuccess : function(result) {
          // alert(JSON.stringify(result));
        },
        onFail : function(err) {}
      })
  });
}