<template>
  <div id="app">
    <!-- <van-tabbar v-if="$route.meta.showTab" v-model="active" route>
      <van-tabbar-item replace to="/catalogue" icon="apps-o">目录</van-tabbar-item>
      <van-tabbar-item replace to="/board" icon="tv-o">看板</van-tabbar-item>
      <van-tabbar-item replace to="/collection" icon="star-o">常用</van-tabbar-item>
      <van-tabbar-item replace to="/Home" icon="user-o">我的</van-tabbar-item>
    </van-tabbar> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  data(){
    return {
      active:0,
    }
  },
  //初始化页面选中状态
  created() {
    //由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
    // console.log(this.$route);//打印当前路由属性
    // if (this.$route.path === '/') {
    //     this.$router.push('/catalogue');
    // }
  }
}
</script>

<style lang="scss">
.van-tabbar{
  z-index: 11 !important;
}
</style>
