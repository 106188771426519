import axios from 'axios';
import {gettoken, getnewtoken} from './util'

// 环境的切换 // 'http://192.168.201.8:8087/'
if (process.env.NODE_ENV == 'development') {    
    axios.defaults.baseURL =  baseUrl;
} else if (process.env.NODE_ENV == 'debug') {    
    axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {    
    axios.defaults.baseURL =  baseUrl;
}

export function get(url, params){
    return new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    });
}

export function post(url, params) {
    var env = localStorage.getItem('env')
    var development = localStorage.getItem('development')
    if(env == 'dd-mobile'){
        if(development == '2'){
            var corpid = localStorage.getItem('corpid'),
            dingcode = localStorage.getItem('dingcode');
        }else{
            var corpid = 'dinged643a5c37eb4937',
            dingcode = '0ab501b4eea13f05a2e4450cb540920b';
        }
        var data =  gettoken(corpid,dingcode);
        return new Promise((resolve, reject) => {
            axios.post(url, JSON.stringify({ ...params, ...data})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err)
            })
        });
    }else if(env == 'com-wx-mobile'){
        let erpUrl = 'https://crm-cloud.yiyao365.cn/clerk/api/mend/clerk/erp/pushToERP';
        // let erpUrl = 'https://cloud-dev.yiyao365.cn/clerk/api/mend/clerk/erp/pushToERP';
        var corpid = localStorage.getItem('corpid'), workWxCode = localStorage.getItem('ynWxUserToken');
        var data = gettoken(corpid,workWxCode);
        let pdata = {
            api: baseUrl + url,
            params: JSON.stringify({...params, ...data})
        }
        return new Promise((resolve, reject) => {
            axios.post(erpUrl, pdata).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err)
            })
        });
    }
}
// 企业微信 鉴权参数获取接口使用
export function firstpost(url, params) {
    let erpUrl = 'https://crm-cloud.yiyao365.cn/clerk/api/mend/clerk/erp/pushToERP';
    // let erpUrl = 'https://cloud-dev.yiyao365.cn/clerk/api/mend/clerk/erp/pushToERP';
    var data =  getnewtoken("000111"+"|@ModuleNo=010804"); // 无用，随机传入的数字
    let pdata = {
        api: baseUrl + url,
        params: JSON.stringify({...params, ...data})
    }
    // alert(JSON.stringify(pdata))
    return new Promise((resolve, reject) => {
        axios.post(erpUrl, pdata).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    });
}
// 钉钉 页面初始化 获取token时使用
export function ddpostToken(url, params) {
    var corpid = 'dinged643a5c37eb4937',
    dingcode = 'a8ddf35883eb3ab1b586355614cd54bd';
    // var corpid = localStorage.getItem('corpid'),
    // dingcode = localStorage.getItem('dingcode');
    var data =  gettoken(corpid,dingcode);
    // alert('corpid='+corpid+'\n\n'+'dingcode='+dingcode+'\n\n'+'token='+JSON.stringify(data));
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify({ ...params, ...data})).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    });
}