import { post, firstpost, ddpostToken } from './http.js'

// 企业微信获取鉴权信息接口
export function WorkWxWebConfig(data){
    return firstpost('API/WorkWx/WorkWxWebConfig', data)
}
// 钉钉页面初始化 获取长久token接口
export function GetToken(data){
    return ddpostToken('API/ERPInventory/GetToken/v1', data)
}

// 1 登录首页
// 1.1 当前用户查询
export function UserQuery(data) { 
    return post('API/ERPInventory/UserQuery/v1', data)
} 
// 1.2 查询组织机构接口
export function SubQuery(data) { 
    return post('API/ERPInventory/SubQuery/v1', data)
}
// 1.3 盘点首页配置查询
export function HomeConfigQuery(data) { 
    return post('API/ERPInventory/HomeConfigQuery/v1', data)
}
// 2 盘点方案列表查询
export function StockTakingQuery(data) {
    return post('API/ERPInventory/StockTakingQuery/v1',data)
}
// 3 盘点方案详情
export function GetStockTaking(data) {
    return post('API/ERPInventory/GetStockTaking/v1',data)
}
// 4 新增方案界面
// 4.1 编号、盘点形式、盘点方式、仓库等信息获取
export function GetStockTakingType(data) {
    return post('API/ERPInventory/GetStockTakingType/v1',data)
}
// 4.2 选择仓库调用接口
export function StockQuery(data) {
    return post('API/ERPInventory/StockQuery/v1',data)
}
// 4.3 完成
export function CreateStockTaking(data) {
    return post('API/ERPInventory/CreateStockTaking/v1',data)
}
// 5 盘点方案弹框界面
// 5.1 盘点明细数据展示
export function StockTakingDetailQuery(data) { 
    return post('API/ERPInventory/StockTakingDetailQuery/v1', data)
}
// 5.2 检测是否存在差异数量 直接点完成时调用
export function FinishStockTakingCheck(data) { 
    return post('API/ERPInventory/FinishStockTakingCheck/v1', data)
}
// 5.3 完成、结束  弹出界面生成差异单 or 生成复盘单
export function FinishStockTaking(data) { 
    return post('API/ERPInventory/FinishStockTaking/v1', data)
}
// 6 盘点详情（商品）
// 6.1 加载盘点明细 
export function GetStockTakingDetailByGoods(data) { 
    return post('API/ERPInventory/GetStockTakingDetailByGoods/v1', data)
}
// 6.2 完成本条盘点
export function StockTakingDetailFinish(data) { 
    return post('API/ERPInventory/StockTakingDetailFinish/v1', data)
}
// 7 批号新增
// 7.1 选择货号数据源
export function GetPHInfo(data) { 
    return post('API/ERPInventory/GetPHInfo/v1', data)
}
// 7.2 保存
export function CreateStockTakingDetail(data) { 
    return post('API/ERPInventory/CreateStockTakingDetail/v1', data)
}
// 选择批号数据源
export function GetPHInfoByPH(data) { 
    return post('API/ERPInventory/GetPHInfoByPH/v1', data)
}
// 8 盘点详情(批号)
// 8.1 加载批号明细
export function GetStockTakingDetailByBatchNo(data) { 
    return post('API/ERPInventory/GetStockTakingDetailByBatchNo/v1', data)
}

// 9.总部抽盘、不停业盘点首页
// 9.1 加载仓库
export function StockTakingStockQuery(data) { 
    return post('API/ERPInventory/StockTakingStockQuery/v1', data)
}
// 10 总部抽盘、不停业盘点方案录入
// 10.1 界面加载
export function GetResultDiffSheetInfo(data) { 
    return post('API/ERPInventory/GetResultDiffSheetInfo/v1', data)
}
// 10.2 扫码 搜索
export function CPBTYStockTakingDetailQuery(data) { 
    return post('API/ERPInventory/CPBTYStockTakingDetailQuery/v1', data)
}
// 10.3 点对号 CreateLog
export function CreateLog(data) { 
    return post('API/ERPInventory/CreateLog/v1', data)
}
// 11.交接班盘点
// 11.1 首页展示
export function ShiftChangeStockTakingQuery(data) { 
    return post('API/ERPInventory/ShiftChangeStockTakingQuery/v1', data)
}
// 11.2 明细展示
export function ShiftChangeStockTakingDetailQuery(data) { 
    return post('API/ERPInventory/ShiftChangeStockTakingDetailQuery/v1', data)
}
// 11.5 选择上任交班人 
export function SubPersionQuery(data) { 
    return post('API/ERPInventory/SubPersionQuery/v1', data)
}
// 11.6 更新上任交班人
export function UpdateLastHandover(data) { 
    return post('API/ERPInventory/UpdateLastHandover/v1', data)
}