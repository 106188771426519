// vant 按需引入配置文件
// 导入所需的组件并注册
import { 
    Button,Tabbar,TabbarItem,Popover,List,Popup,Field,DatetimePicker,
    Cell,CellGroup,Icon,Search,Tab,Tabs,Grid,GridItem,Dialog,Pagination,
    Swipe,SwipeItem,Switch,Col, Row,Overlay,Tag,Divider,Checkbox,CheckboxGroup,
    Stepper,ActionSheet,RadioGroup,Radio,Picker,Toast,PullRefresh,Collapse, CollapseItem
} from 'vant'
const vant = {
    install: function (Vue) {
        Vue.use(Button)
        Vue.use(Tabbar)
        Vue.use(TabbarItem)
        Vue.use(Popover)
        Vue.use(List)
        Vue.use(Popup)
        Vue.use(Field)
        Vue.use(DatetimePicker)
        Vue.use(Cell)
        Vue.use(CellGroup)
        Vue.use(Icon)
        Vue.use(Search)
        Vue.use(Tab)
        Vue.use(Tabs)
        Vue.use(Grid)
        Vue.use(GridItem)
        Vue.use(Dialog)
        Vue.use(Pagination)
        Vue.use(Swipe)
        Vue.use(SwipeItem)
        Vue.use(Switch)
        Vue.use(Col)
        Vue.use(Row)
        Vue.use(Overlay)
        Vue.use(Tag)
        Vue.use(Divider)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(Stepper)
        Vue.use(ActionSheet)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(Picker)
        Vue.use(Toast)
        Vue.use(PullRefresh)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
    }
}

export default vant