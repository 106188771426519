import md5 from './md5.js'

export function gettoken(corpid,dingcode){
    var envdata = localStorage.getItem('env')
    var code
    if(envdata == 'dd-mobile'){
        code = 'DINGCODE'
    }else if(envdata == 'com-wx-mobile'){
        code = 'WORKWXCODE'
    }
    //拼装字符串
    var token = "CORPID="+corpid+"|@"+code+"="+dingcode+"|@"+"ModuleNo=010804";
    // console.log(token)
    //token 进行base64 转换
    token = btoa(token);
    //token base64后的字符串进行字符替换
    token = token.replace(/\+/g, "_").replace(/\=/g, "-").replace(/\//g, "|");
    //前缀拼接 @@
    token="@@"+token;

    var utctime = (new Date()).valueOf();
    var rtime="UTC"+ utctime;
    
    var rsign="RTIME"+rtime+"TOKEN"+token;
    // 参数键从首字母开始，按照ASCII码的顺序进行排序。
    // rsign = that.sort_ascii(rsign);
    //rsign 进行md5 加密（大写）
    rsign = md5.str_md5(rsign);
    // rsign = rsign.toUpperCase();	
    //rsign md5加密后的字符，进行雨诺base64 转换
    var rsigns = btoa(rsign);
    rsigns = rsigns.replace(/\+/g, "_").replace(/\=/g, "-").replace(/\//g, "|");
    // console.log(token);
    return {
        TOKEN: token,
        RTIME: rtime,
        RSIGN: rsigns
    }
}

export function getnewtoken(token){
    var utctime = (new Date()).valueOf();
    // console.log(utctime)
    var rtime="UTC"+ utctime;
    
    var rsign="RTIME"+rtime+"TOKEN"+token;
    // 参数键从首字母开始，按照ASCII码的顺序进行排序。
    // rsign = that.sort_ascii(rsign);
    //rsign 进行md5 加密（大写）
    rsign = md5.str_md5(rsign);
    // rsign = rsign.toUpperCase();	
    //rsign md5加密后的字符，进行雨诺base64 转换
    var rsigns = btoa(rsign);
    rsigns = rsigns.replace(/\+/g, "_").replace(/\=/g, "-").replace(/\//g, "|");
    // console.log(token);
    return {
        TOKEN: token,
        RTIME: rtime,
        RSIGN: rsigns
    }
}