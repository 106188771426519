import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        job:"组织机构",
        gridDisplay:JSON.parse(sessionStorage.getItem("layoutType")), //目录是否九宫格展示
        // personalInfo:{}, // 个人信息
    },
    actions: {
       changeJob(ctx,name){
           console.log("action",ctx,name);
           ctx.commit("changeJob",name);
       },
       changeGrid(ctx,name){
           ctx.commit("changeGrid",name)
       },
    //    changePersonalInfo(ctx,name){
    //        ctx.commit("changePersonalInfo",name)
    //    }
    },
    mutations: {
        changeJob(state,name){
            console.log("mutation",state,name);
            state.job=name;
        },
        changeGrid(state,name){
            sessionStorage.setItem("layoutType", name)
            state.gridDisplay = name
        },
        // changePersonalInfo(state,name){
        //     state.personalInfo = name
        // }
    }
})