// document.documentElement.style.fontSize = document.documentElement.clientWidth /4.14+"px";
// if(document.documentElement.clientWidth < 414){
//     document.documentElement.style.fontSize = document.documentElement.clientWidth / 10.8 + "px";
// }else if(document.documentElement.clientWidth >= 414){
//     document.documentElement.style.fontSize = 414 / 10.8 + 'px' ;
// }else if(document.documentElement.clientWidth >= 768){
//     document.documentElement.style.fontSize = 768 / 10.8 + 'px' ;
// }else if(document.documentElement.clientWidth >= 1024){
//     document.documentElement.style.fontSize = 1024 / 10.8 + 'px' ;
// }

// window.onresize = function(){ //当屏幕发生变化时，修改html的font-size大小
//     if(document.documentElement.clientWidth > 414){
//         document.documentElement.style.fontSize = 
//             414 / 10.8 + 'px' ;
//     }else{
//         document.documentElement.style.fontSize =
//         document.documentElement.clientWidth / 10.8 + "px";
//     }
// }


if(document.documentElement.clientWidth < 750){
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.5 + "px";
}else if(document.documentElement.clientWidth >= 750){
    document.documentElement.style.fontSize = 750 / 7.5 + 'px' ;
}else if(document.documentElement.clientWidth >= 768){
    document.documentElement.style.fontSize = 768 / 7.5 + 'px' ;
}else if(document.documentElement.clientWidth >= 1024){
    document.documentElement.style.fontSize = 1024 / 7.5 + 'px' ;
}

window.onresize = function(){ //当屏幕发生变化时，修改html的font-size大小
    if(document.documentElement.clientWidth > 750){
        document.documentElement.style.fontSize = 
            750 / 7.5 + 'px' ;
    }else{
        document.documentElement.style.fontSize =
        document.documentElement.clientWidth / 7.5 + "px";
    }
}