import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuex from 'vuex'
import store from './store'

Vue.use(Vuex)

// 引入前端UI组件 vant 
// import Vant from 'vant';
import vant from './vant/index'
import 'vant/lib/index.css'
Vue.use(vant)

Vue.config.productionTip = false

import "./stylesheets/reset.css"
import "./stylesheets/colorUniform.css"
import "./stylesheets/rem.js"

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined?'':to.meta.title
  if (to.meta.requireAuth) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath
      } 
    })
  }
  next()
})

// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
